<template>
  <div class="page-content-box">
    <head-main class="head-white no-fixed"></head-main>
    <div class="page-con-top">
      <div class="page-con-model pad-top">
        <h5 class="page-tit">{{ article.title }}</h5>
        <div class="page-con-info">
          <span class="tit-info-span">来源：{{ article.source }}</span>
          <span class="tit-info-span">时间：{{ article.createTime }}</span>
        </div>
        <div class="line-f"></div>
        <div v-if="article.articleImg" class="page-new-img">
          <img :src="article.articleImg" />
        </div>
        <div class="page-content-p" v-html="article.webContent"></div>
      </div>

      <div class="page-con-model" v-if="relaList.length > 0">
        <h5 class="page-tit-s">
          <i class="tit-icon1"></i>
          <span class="tit-red">推荐</span>资讯
          <i class="tit-icon2"></i>
        </h5>
        <div class="zx-con-box">
          <ul class="zx-con-list">
            <li v-for="item in relaList" :key="item.index">
              <div class="zx-div">
                <h5 class="zx-tit" @click="toContent(item)">{{ item.contentName }}</h5>
                <p>{{ item.con }}</p>
                <div class="zx-info-div">
                  <div class="zx-info-l">
                    <el-button v-if="item.isTop === 2" class="zx-btn btn-red">置顶</el-button>
                    <el-button v-else-if="item.isRecommend === 2" class="zx-btn btn-or">热门</el-button>
                    <span class="zx-area">{{ item.source }}</span>
                  </div>
                  <span class="zx-time">{{ item.createTime.split(' ')[0] }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <botto-com class="bottom-white no-fixed"></botto-com>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
export default {
  components: {
    HeadMain,
    BottoCom
  },
  data() {
    return {
      article: {
        title: '',
        source: '',
        createTime: '',
        articleImg: '',
        webContent: ''
      },
      relaList: []
    }
  },
  watch: {
    '$route.query.id': {
      handler(id) {
        this.getContent()
      },
      immediate: true
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'page-body'
  },
  beforeDestroy() {
    document.body.removeAttribute('class', 'page-body')
  },
  methods: {
    getContent() {
      console.log(this.$route.path)
      let url =
        this.$route.path === '/project/article'
          ? '/api/portal/cont/project-article-detail'
          : '/api/portal/cont/pub-detail'
      this.axios
        .get(url, {
          params: {
            id: this.$route.query.id
          }
        })
        .then(res => {
          this.article = res.data.articleData
          this.relaList = res.data.relaList ? res.data.relaList : []
        })
    },
    toContent({ contentUrl, id, contentId }) {
      this.$router.push({ path: contentUrl, query: { id, contentId } })
    }
  }
}
</script>
<style lang="scss" scoped></style>
